<template>
  <v-row class="pa-0 ma-0">
    <v-col cols="12" class="pa-0">
      <v-data-iterator
        v-if="$vuetify.breakpoint.xsOnly"
        disable-pagination
        hide-default-footer
        disable-sort
        :items="datos"
        item-key="id"
        :items-per-page="10"
        :loading="loading"
        :footer-props="{itemsPerPageText: 'Mostrar'}"
      >
        <template v-slot:default="{ items }">
          <template v-for="(item,i) in items">
            <v-card tile outlined class="mx-3 my-2">
              <v-card-title class="d-flex flex-column align-start py-2 blue lighten-5">
                <span class="caption grey--text">Vendedor</span>
                <span class="font-weight-medium primary--text">{{item.ven_des}} <strong>({{item.co_ven}})</strong></span>
              </v-card-title>
              <v-card-text class="pa-0">
                <v-row no-gutters align="center">
                  <template v-for="(field,index) in header_resumen">
                    <v-col v-if="index > 0" cols="3" class="pa-2 grey lighten-5 bule-grey--text font-weight-medium my-1">
                      {{ field.text }}
                    </v-col>
                    <v-col v-if="index > 0" cols="3" class="px-2">
                     <span class="font-weight-bold primary--text"> {{item[field.value]}} </span>
                    </v-col>
                    <v-col v-if="index % 2 === 0" cols="12"><v-divider></v-divider></v-col>
                  </template>
                </v-row>
              </v-card-text>
            </v-card>
          </template>
        </template>
        <template #loading>
          <v-skeleton-loader
            v-bind="attrs"
            type="list-item-two-line, list-item, list-item-two-line"
          ></v-skeleton-loader>
        </template>
        <template v-slot:footer>
          <v-card tile outlined class="mx-3 my-2">
            <v-card-title class="d-flex flex-column align-start py-2 blue-grey lighten-5">
              <span class="font-weight-bold primary--text text-h4">TOTAL</span>
            </v-card-title>
            <v-card-text class="pa-0">
              <v-row no-gutters align="center">
                  <v-col cols="3" class="pa-2 grey lighten-5 bule-grey--text font-weight-medium my-1">
                    C.P
                  </v-col>
                  <v-col cols="3" class="px-2">
                   <span class="font-weight-bold primary--text"> {{totales.clientes_planificados}} </span>
                  </v-col>
                  <v-col cols="3" class="pa-2 grey lighten-5 bule-grey--text font-weight-medium my-1">
                    C.V
                  </v-col>
                  <v-col cols="3" class="px-2">
                   <span class="font-weight-bold primary--text"> {{totales.clientes_visitados}} </span>
                  </v-col>
                  <v-col cols="12"><v-divider></v-divider></v-col>
                  <v-col cols="3" class="pa-2 grey lighten-5 bule-grey--text font-weight-medium my-1">
                    C.A
                  </v-col>
                  <v-col cols="3" class="px-2">
                   <span class="font-weight-bold primary--text"> {{totales.clientes_activados}} </span>
                  </v-col>
                  <v-col cols="3" class="pa-2 grey lighten-5 bule-grey--text font-weight-medium my-1">
                    EVA (80%)
                  </v-col>
                  <v-col cols="3" class="px-2">
                   <span class="font-weight-bold primary--text"> {{totales.eva}} </span>
                  </v-col>
                  <v-col cols="12"><v-divider></v-divider></v-col>
                  <v-col cols="3" class="pa-2 grey lighten-5 bule-grey--text font-weight-medium my-1">
                    EVI
                  </v-col>
                  <v-col cols="3" class="px-2">
                   <span class="font-weight-bold primary--text"> {{totales.evi}} </span>
                  </v-col>
                  <v-col cols="3" class="pa-2 grey lighten-5 bule-grey--text font-weight-medium my-1">
                    CAJAS
                  </v-col>
                  <v-col cols="3" class="px-2">
                   <span class="font-weight-bold primary--text"> {{totales.total_cajas.toFixed(2)}} </span>
                  </v-col>
                  <v-col cols="12"><v-divider></v-divider></v-col>
                  <v-col cols="3" class="pa-2 grey lighten-5 bule-grey--text font-weight-medium my-1">
                    PFV
                  </v-col>
                  <v-col cols="3" class="px-2">
                   <span class="font-weight-bold primary--text"> {{totales.clientes_activados_fdp}} </span>
                  </v-col>
                  <v-col cols="3" class="pa-2 grey lighten-5 bule-grey--text font-weight-medium my-1">
                    P.T
                  </v-col>
                  <v-col cols="3" class="px-2">
                   <span class="font-weight-bold primary--text"> {{totales.cantidad_total_ca}} </span>
                  </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </template>
      </v-data-iterator>
      <v-data-table
        v-else
        :headers="header_resumen"
        :items="datos"
        :loading="loading"
        :footer-props="{itemsPerPageText: 'Mostrar'}"
        disable-pagination
        hide-default-footer
        disable-sort
        dense
        no-data-text="No hay registros disponibles dentro de la Fechas seleccionadas."
        mobile-breakpoint="50"
        class="data-report"
      >
        <template v-slot:item.ven_des="{item}">
          <span class="font-weight-medium primary--text">{{item.ven_des}} <strong>({{item.co_ven}})</strong></span>
        </template>
        <template v-slot:item.cv="{item}">
          <div class="d-flex justify-center">
            <span class="blue-grey--text" v-text="item.cv"></span>
          </div>
        </template>
        <template #loading>
          <v-row>
            <v-col cols="12">
              <v-skeleton-loader
                type="table-tbody"
              ></v-skeleton-loader>
            </v-col>
          </v-row>
        </template>
        <template v-slot:body.append>
          <tr class="header-data" v-if="loading">
            <td colspan="9">
              <v-skeleton-loader
                type="table-row"
              ></v-skeleton-loader>
            </td>
          </tr>
          <tr class="header-data" v-else>
            <td>
              <div class="text-right">
                <span class="font-weight-bold text-h5 black--text">TOTAL</span>
              </div>
            </td>
            <td>
              <div class="text-center">
                <span class="font-weight-bold text-h5" v-text="totales.clientes_planificados"></span>
              </div>
            </td>
            <td>
              <div class="text-center">
                <span class="font-weight-bold text-h5" v-text="totales.clientes_visitados"></span>
              </div>
            </td>
            <td>
              <div class="text-center">
                <span class="font-weight-bold text-h5" v-text="totales.clientes_activados"></span>
              </div>
            </td>
            <td>
              <div class="text-center">
                <span class="font-weight-bold text-h5" v-text="totales.eva"></span>
              </div>
            </td>
            <td>
              <div class="text-center">
                <span class="font-weight-bold text-h5" v-text="totales.evi"></span>
              </div>
            </td>
            <td>
              <div class="text-center">
                <span class="font-weight-bold text-h5">
                  {{totales.total_cajas.toFixed(2)}}
                </span>
              </div>
            </td>
            <td>
              <div class="text-center">
                <span class="font-weight-bold text-h5" v-text="totales.clientes_activados_fdp"></span>
              </div>
            </td>
            <td>
              <div class="text-center">
                <span class="font-weight-bold text-h5" v-text="totales.cantidad_total_ca"></span>
              </div>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'DataResumen',
  props:{
    datos: {
      type: Array,
      default: () => ([]),
    },
    totales: {
      type: Object,
      default: () => ({}),
    },
    loading:{
      type: Boolean,
      default: false
    },
  },
  data: () => ({
    header_resumen:[
      { text: 'Vendedor', value: 'ven_des', class: 'header-data' },
      { text: 'C.P', value: 'clientes_planificados', class: 'header-data', align: 'center'},
      { text: 'C.V', value: 'clientes_visitados', class: 'header-data', align: 'center'},
      { text: 'C.A', value: 'clientes_activados', class: 'header-data', align: 'center'},
      { text: 'EVA (80%)', value: 'eva', class: 'header-data', align: 'center'},
      { text: 'EVI', value: 'evi', class: 'header-data', align: 'center'},
      { text: 'CAJAS', value: 'cant_cajas', class: 'header-data', align: 'center'},
      { text: 'PFV', value: 'clientes_activados_fdp', class: 'header-data', align: 'center'},
      { text: 'PT', value: 'total_pedidos', class: 'header-data', align: 'center'},
    ]
  })
}
</script>

<style lang="sass">
.data-report tbody tr:nth-of-type(odd)
  background-color: #FAFAFA
  border-color: #ECEFF1 !important

// .data-report table > tbody > tr > td:nth-child(1)
//   position: sticky !important
//   position: -webkit-sticky !important
//   left: 0
//   z-index: 98


// .data-report table > thead > tr > th:nth-child(1)
//   z-index: 99


</style>
